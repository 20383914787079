<template>
  <a-layout>
    <app-sidebar />
    <a-layout class="position-relative">
      <mobile-menu />
      <app-header />
      <app-content />
    </a-layout>
  </a-layout>
  <tawk-chatbot />
</template>
<script>
import {
  defineComponent,
  ref,
  onBeforeMount,
  onBeforeUnmount,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import Sidebar from './SidebarV2.vue';
import Header from './HeaderV2.vue';
import Content from './ContentV2.vue';
import MobileMenu from './MobileMenu.vue';
import { roles } from 'src/config/roles-config';
import { mapGetters } from 'vuex';
import TawkChatbot from '@/components/TawkChatbot';
import AutoLogout from 'src/services/utils/autoLogout';

export default defineComponent({
  components: {
    TawkChatbot,
    AppSidebar: Sidebar,
    AppHeader: Header,
    AppContent: Content,
    MobileMenu,
  },
  setup() {
    const store = useStore();
    const timer = ref(null);
    const isTimeout = ref(false);

    watch(isTimeout, (value) => {
      if (value) store.dispatch('userLogout');
    });

    const setUpTimer = () => {
      timer.value = new AutoLogout({
        timeout: 600,
        onTimeout: () => {
          isTimeout.value = true;
        },
        onExpired: () => {
          isTimeout.value = true;
        },
      });
    };

    const resetTimer = () => {
      if (timer.value) timer.value.cleanUp();
    };

    // onBeforeMount(setUpTimer);
    // onBeforeUnmount(resetTimer);

    return { roles };
  },
  computed: { ...mapGetters(['role']) },
});
</script>
