<template>
  <a-button
    :type="buttonType"
    :loading="isLoading"
    style="margin-right:16px"
    @click="downloadInstaller"
  >
    Download Installer
  </a-button>
  <a-button
    :loading="isUpdateLoading"
    :type="buttonType"
    style="margin-right:16px"
    @click="downloadUpdate"
  >
    Download Update
  </a-button>
</template>

<script>
import generic from '../../../services/generic';
import { inject, ref } from 'vue';
import { downloadFileUsingUrl } from '../Helpers/downLoadFIleUsingUrl';

export default {
  name: 'InstallerButton',
  props: {
    buttonType: {
      type: String,
      default: 'default',
    },
  },
  setup() {
    const toast = inject('toast');
    const isLoading = ref(false);
    const isUpdateLoading = ref(false);
    const downloadInstaller = async () => {
      isLoading.value = true;
      const [error, data] = await generic.getInstallerUrl();
      isLoading.value = false;
      error ? toast.error('An error occurred') : downloadFileUsingUrl(data.url);
    };
    const downloadUpdate = async () => {
      isUpdateLoading.value = true;
      const [error, data] = await generic.getUpdateInstallerUrl();
      isUpdateLoading.value = false;
      error ? toast.error('An error occurred') : downloadFileUsingUrl(data);
    };

    return {
      isLoading,
      isUpdateLoading,
      downloadInstaller,
      downloadUpdate,
    };
  },
};
</script>

<style scoped></style>
