<template>
  <a-layout-sider
    v-model:collapsed="sidebar"
    :trigger="null"
    collapsible
    class="d-none d-sm-none d-md-block"
    width="250"
    theme="light"
    :collapsedWidth="50"
  >
    <a-menu v-model:selectedKeys="currentRoute" mode="inline">
      <a-menu-item class="flex disable-click">
        <img alt="logo" width="55" :src="logoMini" v-if="sidebar" />
        <img alt="logo" width="200" :src="logo" v-else />
      </a-menu-item>
      <a-divider class="my-3" />
      <a-menu-item
        :key="route.name"
        v-for="route in items"
        :style="{ 'font-weight': !route.to && 'bold' }"
        :class="{ 'disable-click': !route.to }"
        class="sidebar-item"
        @click="redirectTo(route.name)"
        :id="route.name + '-sidelink'"
      >
        <span class="anticon mr-2" v-if="route.to">
          <i :class="route.class" />
        </span>
        <span>{{ route.name }}</span>
      </a-menu-item>
    </a-menu>
  </a-layout-sider>
</template>
<script>
import logo from '../../assets/img/signature-logo.png';
import logoMini from '../../assets/img/new-logo.png';
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from '@ant-design/icons-vue';
import { ref } from 'vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
  },
  setup() {
    return {
      logo,
      logoMini,
      selectedKeys: ref(['2']),
    };
  },
  computed: {
    ...mapGetters([
      'sidebarItems',
      'currentRoute',
      'getSidebarState',
      'getTheme',
    ]),
    items() {
      if (this.sidebar) {
        return this.sidebarItems.filter((e) => e.to);
      }
      return this.sidebarItems;
    },
    sidebar: {
      get() {
        return this.$store.state.sidebar;
      },
      set(value) {
        this.$store.commit('updateSidebarState', value);
      },
    },
    currentRoute: {
      get() {
        return this.$store.state.currentRoute;
      },
      set(value) {
        this.$store.commit('changeRoute', value);
      },
    },
  },
  methods: {
    redirectTo(name) {
      this.$router.push({ name });
      //   this.changeRoute(name);
      //   this.closeSidebar();
    },
  },
};
</script>
<style>
aside {
  box-shadow: rgb(0 0 0 / 8%) 0.125rem 0 0.25rem;
  z-index: 11;
  overflow: auto;
  height: 100vh;
}

.mini-logo {
  margin-top: 0 !important;
  margin-bottom: 0.85em !important;
}

.sidebar-item > .ant-menu-title-content {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
