import apiHelper from './index';

async function getInstallerUrl(spinner = false) {
  return await apiHelper.get('binary_deployment/installer_link/', false);
}
async function getUpdateInstallerUrl(spinner = false) {
  return await apiHelper.get('binary_deployment/updater_link/', false);
}

export default { getInstallerUrl, getUpdateInstallerUrl };
