<template></template>

<script>
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      userName: localStorage.getItem('name'),
      userEmail: localStorage.getItem('email'),
    };
  },
  computed: { ...mapGetters(['token']) },
  mounted() {
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_API.customStyle = this.getCustomStyles();

    (function() {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/625ce4597b967b11798b2ca8/1g0tdo0fj';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
    this.setHandler();
  },
  methods: {
    setHandler() {
      window.Tawk_API.onChatStarted = this.onChatStarted;
      window.Tawk_API.onChatMaximized = this.onChatMaximized;
      window.Tawk_API.onLoad = window.Tawk_API.minimize;
      this.emitter.on('chatbot-visibility', this.toggleChatVisibility);
    },

    async toggleChatVisibility(state) {
      if (state) window.Tawk_API.showWidget();
      else {
        window.Tawk_API.hideWidget();
        window.Tawk_API.minimize();
      }
    },

    async onChatStarted() {
      const client_data = {
        user: this.userName,
        user_email: this.userEmail,
      };
      await axios({
        method: 'post',
        url:
          'https://xl2qanhuq8.execute-api.us-east-2.amazonaws.com/default/tawk_io_notification',
        data: JSON.stringify(client_data),
      });
    },

    getCustomStyles() {
      return {
        visibility: {
          desktop: {
            position: 'br',
            xOffset: 20,
            yOffset: 20,
          },
          mobile: {
            position: 'br',
            xOffset: 0,
            yOffset: 0,
          },
          bubble: {
            rotate: '0deg',
            xOffset: -20,
            yOffset: 0,
          },
        },
      };
    },

    onChatMaximized() {
      if (!this.token) {
        window.Tawk_API.minimize();
        return;
      }
      console.log({ debug_key: 'test' });
      let hash = CryptoJS.HmacSHA256(
        localStorage.getItem('email'),
        '761f5d9938860284fb95b14609ab7aef9239fbb3'
      ).toString();
      window.Tawk_API.setAttributes(
        {
          fancyatt: 'testing',
          //DONT PUT SPACE OR UNDERSCORE IN KEY, IT WILL THROW INVALID ATTRIBUTE ERROR
          userName: this.userName,
          hostUrl: localStorage.getItem('hostURL'),
          userEmail: this.userEmail,
          organization: localStorage.getItem('organization'),
          name: localStorage.getItem('name'),
          email: localStorage.getItem('email'),
          hash: hash,
        },
        function(error) {
          console.log(error);
        }
      );
    },
  },
};
</script>

<style></style>
